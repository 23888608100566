import { fabClasses } from "@mui/material";
import {
  YMaps,
  Map,
  Placemark,
  SearchControl,
  TypeSelector,
  Circle,
} from "@pbe/react-yandex-maps";

const DeliveryInfo = () => (
  <div className="modal__body">
    <h3 className="modal__title">Информация о доставке</h3>
    <div className="zones-table">
      <p className="zones-table__head">Минимальная сумма заказа</p>
      <p className="zones-table__head">Время ожидания</p>
      <p className="zones-table__head">Зона на карте города</p>
      <p className="zones-table__text">от 650₽</p>
      <p className="zones-table__text">1 ч.</p>
      <p className="zones-table__text">синяя</p>
      <p className="zones-table__text">от 1250₽</p>
      <p className="zones-table__text">1 ч. 20 мин. - 1ч. 30 мин.</p>
      <p className="zones-table__text">зеленая</p>
      <p className="zones-table__text">от 1250₽</p>
      <p className="zones-table__text">1 ч. 20 мин. - 1ч. 30 мин.</p>
      <p className="zones-table__text">жёлтая</p>
      <p className="zones-table__text">от 1650₽</p>
      <p className="zones-table__text">1 ч. 30 мин. - 1ч. 40 мин.</p>
      <p className="zones-table__text">красная</p>
      <p className="zones-table__text">от 2250₽</p>
      <p className="zones-table__text">1 ч. 40 мин. - 1ч. 50 мин.</p>
      <p className="zones-table__text">оранжевая</p>
      <p className="zones-table__text">от 3000₽</p>
      <p className="zones-table__text">1 ч. 50 мин.</p>
      <p className="zones-table__text">фиолетовая</p>
    </div>
    <p className="modal__text">
      Самовывоз возможен с&nbsp;10:30 до&nbsp;01:30 по&nbsp;адресу: <br />
      г.&nbsp;Красноярск, ул.&nbsp;Парижской&nbsp;коммуны&nbsp;31,
      цокольный&nbsp;этаж.
    </p>
    <p className="modal__text modal__text--yellow">
      Скидка при&nbsp;самовывозе 15% на&nbsp;любой заказ.
    </p>
    <p className="modal__text">
      Вы можете выбрать удобное для&nbsp;вас время доставки при&nbsp;оформлении
      заказа.
    </p>
    <div style={{ position: "relative", overflow: "hidden" }}>
      <iframe
        src="https://yandex.ru/map-widget/v1/?um=constructor%3A9c8fed5d400e63ee7a0892646420ba4a678fbe4226e98bb0af944651ab6bb442&amp;source=constructor"
        width="100%"
        height="400"
        frameBorder="0"
        allowFullScreen={false}
        style={{ position: "relative" }}
        title="Зоны доставки XLFood"
      />
      {/* <YMaps>
        <Map modules={['geoObject.addon.balloon']} defaultState={{ center: [56.014749, 92.880588], zoom: 11 }} width={"100%"} height={400}>
          <Placemark
            key={1}
            defaultGeometry={[56.014881, 92.880491]}
            options={{
              iconImageSize: [10, 10],
              preset: "islands#foodIcon",
              iconColor: 'orange'
            }}
          />
          <Circle
            geometry={[[56.014749, 92.880588], 30000]}
            options={{
              openEmptyBalloon: true,
              draggable: false,
              fillColor: "#0000ee22",
              strokeColor: "#0000ee",
              strokeOpacity: 0.8,
              strokeWidth: 4,
              hasBalloon: true,
              openBalloonOnClick: true
            }}
            properties={{
              balloonContent: "от 500₽",
              hintContent: "123"
            }}
          />
          <Circle
            geometry={[[56.014749, 92.880588], 15000]}
            options={{
              openEmptyBalloon: true,
              draggable: false,
              fillColor: "#ffa50044",
              strokeColor: "#ffa500",
              strokeOpacity: 0.8,
              strokeWidth: 4,
              hasBalloon: true,
              openBalloonOnClick: true
            }}
            properties={{
              balloonContent: "300₽",
              hintContent: "123"
            }}
          />
          <Circle
            geometry={[[56.014749, 92.880588], 10000]}
            options={{
              openEmptyBalloon: true,
              draggable: false,
              fillColor: "#00800066",
              strokeColor: "#008000",
              strokeOpacity: 0.9,
              strokeWidth: 4,
              hasBalloon: true,
              openBalloonOnClick: true
            }}
            properties={{
              balloonContent: "200₽",
              hintContent: "123"
            }}
          />

        </Map>
      </YMaps> */}
    </div>
    <h4 className="modal__subtitle">Условия доставки</h4>
    <ul className="modal__list">
      <li></li>
      <li>
        Минимальная сумма заказа бесплатной доставки 650&nbsp;руб. Стоимость заказа может быть
        увеличена, когда&nbsp;он сделан в&nbsp;удаленные районы города.
      </li>
    </ul>
    <p className="modal__small-text">
      В случае если минимальная сумма вашего заказа не соответствует бесплатной доставке в ваш район города, то стоимость платной доставки будет составлять сумму,недостающую для бесплатной доставки, в зависимости от удаленности зоны.
    </p>
    <p className="modal__small-text">
      Оплатить заказ можно наличными или банковской картой при получении заказа
      от курьера. Мы принимаем карты Visa, MasterCard, МИР и Maestro.
    </p>
  </div>
);

export default DeliveryInfo
